
import { CheckOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { defineComponent, UnwrapRef, ref, reactive, watch, toRefs, PropType, toRef } from "vue";
import { IPermission, EPermissionMenu, EPermissionMenuCheckList } from "@/_utils/types";
import { useRequest } from 'vue-request';
import { bigDataService, academyService, eLearningService } from '@/_services';
interface FormState {
  nameTh: string;
  nameEn: string;
}
export default defineComponent({
	props: {
		visible: Boolean,
        type: {
            type: String,
            validator: (val: string) => ["bigData", "academy", "eLearning"].includes(val),
        },
        permission: {
            type: Object as PropType<IPermission>,
            required: false
        }
	},
    components: {
        CheckOutlined,
        CloseOutlined
    },
    setup(props, { emit }) {
        const type = toRef(props, 'type')
        const service = type.value === 'bigData'? bigDataService: (type.value === 'academy' ? academyService : eLearningService)
        const permissionMenuList = reactive([...EPermissionMenu])
        const permission = toRef(props, 'permission')
        const isLoading = ref(false)
        const formRef = ref()
        const formState: UnwrapRef<FormState> = reactive({
            nameTh: '',
            nameEn: ''
        });
        const state = reactive({
            checkboxListTmp: [...EPermissionMenuCheckList],
            status: false,
            message: {
                text: '',
                type: ''
            }
        });
        watch([permission], () => {
            state.checkboxListTmp = [...EPermissionMenuCheckList.map(ele=> {
                ele._ = []
                return ele
            })]
            if(!permission.value){
                return;
            }
            const perm = permission.value
            const { permission_menu } = perm
            Object.keys(permission_menu).forEach(menuKey => {
                // eslint-disable-next-line no-prototype-builtins
                let tmp = state.checkboxListTmp.find(ele => ele.hasOwnProperty(menuKey)) as any
                if (tmp) {
                    tmp[menuKey] = permission_menu[menuKey]
                    if(permission_menu[menuKey] === true) {
                        if(menuKey.includes('_edit') && !tmp._.includes('B')){
                            tmp._.push('B')
                        }else{
                            if(!tmp._.includes('A')){
                                tmp._.push('A')
                            }
                        }
                    } 
                }
            })
            formState.nameTh = perm.permission_name_th
            formState.nameEn = perm.permission_name_en
            state.status = perm.active ?? false
        }, {deep: true})

        const { data: updatedResult, run: updatePermission } = useRequest(service.updatePermission, {
            manual: true,
            onSuccess:() => {
                console.log('updatedResult', updatedResult)
                isLoading.value = false
                if (updatedResult.value?.success) {
                    state.message.text = 'Updated permission successful'
                    state.message.type = 'info'
                    setTimeout(()=>{
                        dismissForm()
                    }, 1000)
                }
            },
            onError: (error) => {
                console.log('error', error)
                isLoading.value = false
                state.message.text = 'Cannot update permission, please try again later'
                state.message.type = 'error'
            }
        })

        const { data: createdResult, run: createPermission } = useRequest(service.createPermission, {
            manual: true,
            onSuccess:() => {
                console.log('createdResult', createdResult)
                isLoading.value = false
                if (createdResult.value?.success) {
                    state.message.text = 'Created permission successful'
                    state.message.type = 'info'
                    setTimeout(()=>{
                        dismissForm()
                    }, 1000)
                }
            },
            onError: (error) => {
                console.log('error', error)
                isLoading.value = false
                state.message.text = 'Cannot create permission, please try again later'
                state.message.type = 'error'
            }
        })

        const handleFormFinish = () => {
            const payload = {
                "permission_name_th": formState.nameTh,
                "permission_name_en": formState.nameEn,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                "permission_menu": state.checkboxListTmp.reduce((acc, cur: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let result: any = {}
                    Object.keys(cur).filter(k => !['key', '_'].includes(k)).forEach(ele => {
                        result[ele] = cur[ele]
                    })
                    return Object.assign(acc, result)
                } , {}),
                "active": state.status
            }

            isLoading.value = true
            if (permission.value) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                updatePermission(permission.value.id!, payload)
            } else {
                createPermission(payload)
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onChange = (changed: any, checkedValue: string[]) => {
            if(checkedValue.includes("B")){
                changed._ = ["A", "B"]
                Object.keys(changed).filter(k => !['key', '_'].includes(k)).forEach(e => {
                    changed[e] = true
                })
            } else if (checkedValue.length) {
                changed._ = ["A"]
                Object.keys(changed).filter(k => !['key', '_'].includes(k)).forEach(e => {
                    changed[e] = !e.includes('_edit')
                })
            } else {
                changed._ = []
                Object.keys(changed).filter(k => !['key', '_'].includes(k)).forEach(e => {
                    changed[e] = false
                })
            }
        }
        const dismissForm = () => {
            formState.nameTh = ''
            formState.nameEn = ''
            formRef.value.resetFields()
            state.checkboxListTmp = [...EPermissionMenuCheckList]
            state.message.type = '';
            state.message.text = '';
            emit('closeModal')
        }

        return {
            formRef,
            formState,
            ...toRefs(state),
            handleFormFinish,
            dismissForm,
            onChange,
            permissionMenuList,
            EPermissionMenuCheckList,
            isLoading
        }
    }
})
